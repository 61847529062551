import React from 'react'
import styled from 'styled-components'

import Layout from '../templates/Layout'
import { BACKGROUND, PRIMARY, SECONDARY } from '../constants/colors'
import { Container } from '../components/shared'
import { withErrorHandler } from '../components/errorHandler'

const Title = styled.div`
  color: ${SECONDARY};
  font-size: 30px;
  margin: 30px 0px;
`

const Body = styled.div`
  color: ${PRIMARY};
  font-size: 18px;
  margin: 15px 0px;
`

const Header = styled.div`
  color: ${SECONDARY};
  font-size: 36px;
  line-height: 48px;
  margin-top: 48px;
  margin-bottom: 36px;
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${BACKGROUND};
`

const Wrap = styled.div`
  max-width: 80%;
  margin-bottom: 48px;
`

const Privacy = () => (
  <Layout>
    <Container background={BACKGROUND}>
      <Flex>
        <Wrap>
          <Header>Privacy Policy</Header>
          <Body>Last updated: September 25th, 2020</Body>
          <Title>1. OVERVIEW</Title>
          <Body>
            This is the Privacy Policy of Blue Light Holdings Inc. (“Blue
            Light”), which provides an online platform (“our service”) that
            allows video, photo and text content owners and licensors (each a
            “Producer”) to offer their video, photo and text programming to
            consumers via Producer-branded websites and applications (each set
            of Producer-branded sites and apps, a “Producer Channel”).
          </Body>
          <Body>
            Consumers: In order to access a Producer Channel, a consumer (“you”)
            must create an account with the applicable Producer. By creating an
            account, you agree to provide certain personal information to the
            Producer and Blue Light. When Blue Light collects, stores and
            processes that information, it does so on behalf of the applicable
            Producer and at its direction to, among other things, allow the
            Producer to provide you with the applicable Producer Channel.
          </Body>
          <Body>
            Producers: This Privacy Policy does not apply to Producers.
          </Body>
          <Body>
            Acceptance: By using our service, including any Producer Channel,
            you agree to the collection, use, and disclosure of your information
            as set forth in this Privacy Policy. We may modify this Privacy
            Policy from time to time. Your continued use of our services
            constitutes your agreement to any updated Privacy Policy on a
            prospective basis.
          </Body>
          <Title>2. ACCOUNT SECURITY</Title>
          <Body>
            We use security measures to protect the loss, misuse, and
            unauthorized alteration of the information under our control. Please
            be advised, however, that we cannot guarantee that our security
            measures will prevent disruptions or unauthorized access from
            occurring.
          </Body>
          <Body>
            You are solely responsible for (a) maintaining the security of your
            account(s) and (b) all activity that occurs under your account(s).
            You must notify us immediately if you suspect any unauthorized
            access to or use of your account(s). If you forget your account
            login information, you may request via email to{' '}
            <a href="mailto:support@solinfitness.com">
              support@solinfitness.com
            </a>{' '}
            that we send it to the email address associated with your
            account(s).
          </Body>
          <Title>3. INFORMATION WE COLLECT</Title>
          <Body>
            Consumer Information: Blue Light and individual Producers may
            jointly collect information from and about you, such as your account
            registration information, such as your name and email address, your
            purchase and free-trial sign-up history, your Producer Channel usage
            history, traffic source (e.g., how you found the Producer Channel),
            and your general geographic location (based upon IP address).
          </Body>
          <Body>
            Payment Information: If you make a purchase or sign up for a free
            trial through Blue Light, you must provide valid payment card
            information. This information is collected directly by our payment
            vendors and is not received or stored by Blue Light or any Producer.
          </Body>
          <Body>
            Information Collected Automatically: Blue Light collects information
            about your use of our websites and applications. This may include
            your activities, your IP address, location information, your browser
            type, your Internet service provider (ISP), referring/exit pages,
            your operating system or device type, date/time stamps, and related
            metadata.
          </Body>
          <Body>
            Additional Producer Collection of Information: Producer may collect
            and/or process personal information entirely outside of the Blue
            Light Service. Any processing of your personal information by
            Producer which takes place outside of the Blue Light Service is not
            contemplated here, and is governed exclusively by the Producer’s
            privacy policy.
          </Body>
          <Body>
            Comments: You may choose to post a comment or participate in a
            forum, where available. This information will be available to the
            public. You may also choose to provide other information about
            yourself in connection with surveys, contests, special offers,
            customer support inquiries, and other communications with us.
          </Body>
          <Title>
            4. TRACKING TECHNOLOGIES AND THIRD-PARTY DATA COLLECTION
          </Title>
          <Body>
            Tracking Technologies: Producers use standard tracking technologies
            such as pixels and cookies to track user activity on each Producer
            Channel..
          </Body>
          <Body>
            Third-Party Platforms: When you use an application that runs on a
            third-party platform (e.g., iOS, Roku, etc.), the provider of that
            third-party platform may collect information about you and your use
            of our application.
          </Body>
          <Body>
            Analytics and Advertising: We use third-party analytics services to
            collect data about usage of our services. A Producer may allow
            third-party advertising companies (including advertising agencies,
            networks, and delivery vendors) to collect data regarding
            advertisements that they may place on our websites. Third-party
            analytics providers and advertising companies may use cookies to
            track your activities.
          </Body>
          <Title>5. HOW WE USE YOUR INFORMATION</Title>
          <Body>
            Blue Light and/or the relevant Producer may use the information
            collected about you to fulfill your orders and deliver video
            programming and other goods and services to you, provide customer
            support to you, analyze how our services are being used, communicate
            with you, verify your eligibility to make purchases and participate
            in offers, collect any debts, prevent fraud, and otherwise protect
            the integrity of our systems, enforce our legal rights, perform any
            functions described at the time of collection; and otherwise operate
            our business.
          </Body>
          <Title>6. DISCLOSURES TO THIRD PARTIES</Title>
          <Body>
            Blue Light and/or the relevant Producer may disclose information
            about you as follows:
          </Body>
          <Body>
            Authorized Service Providers: We may disclose your information to
            service providers involved in operating the Blue Light Service. This
            includes payment providers, email service providers, content
            delivery networks, cloud storage providers, analytics companies, and
            professionals such as accountants and lawyers.
          </Body>
          <Body>
            Legal Situations: We may disclose your information when we believe
            in good faith that (i) disclosure is required by a search warrant,
            subpoena (whether criminal or civil), civil investigative demand,
            court order, or a request for cooperation from a law enforcement or
            other government agency; (ii) disclosure is appropriate and
            necessary to report a suspected crime (including any case of
            suspected child exploitation) or prevent physical harm to an
            individual or property; (iii) disclosure is appropriate and
            necessary to enforce our rights; and (iv) disclosure is required by
            law. Nothing in this Privacy Policy shall be deemed to create any
            third-party right, and we reserve the right to object to any
            third-party requests for information in appropriate circumstances.
          </Body>
          <Body>
            Corporate Transactions: In the event Blue Light is involved in a
            business transition that involves the sale, merger, or divestment of
            Blue Light, we may disclose any information about you to the
            acquiring entities. The transferred information will remain subject
            to the provisions of this Privacy Policy, as updated.
          </Body>
          <Body>
            To the Public: We may publicly disclose aggregated user statistics
            and other information, which is not considered personal information.
          </Body>
          <Title>7. EMAILS</Title>
          <Body>
            When you create an account, you may receive certain emails:
          </Body>
          <Body>
            Transaction emails: When you first create an account, we may send
            you a welcome email that provides information about your
            subscription and your account. If you make a purchase, we may send
            you an email confirming your purchase. We may also, from time to
            time, send you other emails concerning your account status. You may
            not opt out of receiving transactional emails.
          </Body>
          <Body>
            Emails from Producers: When you purchase a subscription, you may
            agree to receive emails from the Producer: i) producers may, via the
            Blue Light Service, send emails concerning the availability of new
            or scheduled video programming in a subscription that you have
            purchased. If you decide you no longer wish to receive these emails,
            you may opt out as instructed in each email or in your account
            settings ii.) some Producers may email you directly (i.e., through
            non-Blue Light systems). Blue Light does not control these emails
            and opting out of Blue Light-sent emails will not cause an opt-out
            with respect to these emails. To opt out of emails sent directly by
            Producers, please follow the opt-out instructions set forth in the
            Producers’ emails.
          </Body>
          <Title>8. YOUR PRIVACY RIGHTS</Title>
          <Body>
            You may change your account information or close your account(s) at
            any time by logging into your account and selecting Manage Account.
            If you close an account, you may lose access to video programming
            that you have purchased. We may preserve your account information
            (i) for a period of time in case of accidental deletion or in case
            you change your mind; and (ii) where we believe in good faith that
            preservation is required by law or necessary to enforce our rights.
          </Body>
          <Body>
            The following rights apply only to the extent the relevant Producer
            is subject to the GDPR (for EEA users) and/or the California
            Consumer Privacy Act of 2018, as amended (the “CCPA”) (for
            California users).
          </Body>
          <Body>EEA Users:</Body>
          <Body>
            Users from the EEA have the right to: opt out of cookies; access,
            correct, delete, restrict or object to our use of their personal
            data; be forgotten; and data portability. Please email{' '}
            <a href="mailto:support@solinfitness.com">
              support@solinfitness.com
            </a>{' '}
            if you wish to opt out.
          </Body>
          <Body>
            If you require assistance or have a specific request that is not
            presently covered by our product features, please contact our{' '}
            <a href="mailto:support@solinfitness.com">
              support@solinfitness.com
            </a>
            . We may require additional information from you to fulfil your
            requests. We may charge a reasonable fee if your request imposes an
            unreasonable cost. We will afford UK-based users with all rights
            available to EU users, regardless of the UK’s EU member status.
          </Body>
          <Body>California Users:</Body>
          <Body>
            Users from California have the right to: request information about
            the categories and sources of personal information collected about
            you, and the associated purposes for collection and third-party
            disclosures; request a copy of your personal information; request
            deletion of your personal information; and opt out of the sale of
            your personal information.
          </Body>
          <Body>
            To exercise your right to access or delete your personal
            information, we must be able to verify your identity as the owner of
            the account you are inquiring about. We may not be able to fulfill
            your request until we can do so. Although you do not need to have an
            Blue Light account to submit a request, we may not be able to locate
            certain information to process your request if you don’t have one.
          </Body>
          <Body>Nevada Users:</Body>
          <Body>
            Nevada residents have the right to opt out of the sale of certain
            &quot;covered information&quot; collected by operators of websites
            or online services.
          </Body>
          <Title>8. COOKIE POLICY</Title>
          <Body>Tracking And Cookies Data</Body>
          <Body>
            We use cookies and similar tracking technologies to track the
            activity on Producer Channels and we hold certain information.
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and a analyze Producer Channels. You can
            instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may
            not be able to use some portions of Producer Channels.
          </Body>
          <Body>
            Producer Channels may use third party services, including, without
            limitation, Google Analytics, a web analytics service provided by
            Google, Inc. (“Google”) that uses cookies to help us analyze how
            users use Producer Channels. The information generated by the cookie
            about your use of Producer Channels (including your IP address) will
            be transmitted to and stored by Google on our other third party
            service providers. Google and our other third party service
            providers use this information for the purpose of evaluating your
            use of Producer Channels, compiling reports on website activity for
            website operators and providing other services relating to website
            activity and internet usage. Our third party service providers may
            also transfer this information to third parties where required to do
            so by law, or where such third parties process the information on
            their behalf.
          </Body>
          <Title>9. CHILDREN&apos;S PRIVACY</Title>
          <Body>
            Blue Light does not knowingly collect any personal information from
            individuals under the age of 16. If you are a parent or legal
            guardian who has discovered that your child has provided personal
            information through the Blue Light Service without your consent, you
            can ask us to remove such unauthorized information by contacting us
            at{' '}
            <a href="mailto:support@solinfitness.com">
              support@solinfitness.com
            </a>
            .
          </Body>
          <Title>10. THIRD-PARTY SERVICES</Title>
          <Body>
            We or a Producer may provide links to third-party websites and
            applications. We have no control over such services and therefore
            refer you to their privacy policies for information on their
            respective privacy practices.
          </Body>
          <Title>11. DISPUTE RESOLUTION</Title>
          <Body>
            We are committed to working with you to obtain a fair resolution of
            any complaint. We encourage you to begin by contacting us as{' '}
            <a href="mailto:support@solinfitness.com">
              support@solinfitness.com
            </a>
            . We endeavor to respond to requests promptly.
          </Body>
          <Body>
            E.U. residents have the right to lodge complaints with the data
            protection authority of their country of residence.
          </Body>
          <Title>12. CONTACT US</Title>
          <Body>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{' '}
            <a href="mailto:support@solinfitness.com">
              support@solinfitness.com
            </a>
            .
          </Body>
        </Wrap>
      </Flex>
    </Container>
  </Layout>
)

export default withErrorHandler(Privacy)
